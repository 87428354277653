<script src="../../store/modules/group.js"></script>
<template>
  <section class="q-pa-md">
    <h1>Sign in</h1>
    <q-form
      autofocus
      class="q-gutter-md"
      @submit.prevent="submit"
    >
      <q-input
        v-model="innerValue.email"
        label="Email"
        type="email"
      />
      <q-input
        v-model="innerValue.password"
        label="Password"
        type="password"
      />
      <q-btn
        color="primary"
        label="Sign in"
        type="submit"
      />
      <p>
        Don't have an account?
        <q-btn
          :to="`/${$route.params.locale}/sign-up`"
          label="Sign up"
        />
      </p>
      <p>
        Forgot your password?
        <q-btn
          :to="`/${$route.params.locale}/reset-password`"
          label="Reset password"
        />
      </p>
    </q-form>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions, mapState } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    computed: {
      ...mapState(
        [
          'data',
        ],
      ),
    },
    meta: {
      title: 'Sign in',
    },
    methods: {
      ...mapActions(
        [
          'signIn',
        ],
      ),
      async submit () {
        try {
          await this.signIn(
            this.innerValue,
          )
        } catch (error) {
          return
        }

        await this.$router.push(
          `/${this.$route.params.locale}/dashboard`,
        )
      },
    },
    data () {
      return {
        innerValue: {},
      }
    },
    async mounted () {
      if (this.$_.get(data, 'id')) {
        await this.$router.push(
          `/${this.$route.params.locale}/dashboard`,
        )
      }
    },
  }
</script>
